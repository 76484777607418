var w = Object.defineProperty;
var y = (l, i, t) => i in l ? w(l, i, { enumerable: !0, configurable: !0, writable: !0, value: t }) : l[i] = t;
var p = (l, i, t) => y(l, typeof i != "symbol" ? i + "" : i, t);
import { N as f } from "./chunks/NewsMediaPulseTracker.js";
import { l as S } from "./chunks/index.js";
import { setLoginFlowState as T, getUTMParams as v, fetcher as L, enhanceSalesposterResponse as I, toSlug as u } from "@vgno/utils";
const g = {
  dinepenger: "dp",
  vg: "vg"
};
class _ extends HTMLElement {
  constructor() {
    super();
    p(this, "contentId");
    p(this, "contentType");
    p(this, "deviceType");
    p(this, "isApp");
    p(this, "newsroom");
    p(this, "state");
    this.contentId = this.dataset.contentId || "unknown", this.contentType = this.dataset.contentType, this.deviceType = this.dataset.deviceType, this.isApp = this.dataset.isApp === "true", this.newsroom = this.dataset.newsroom || "vg", this.state = {
      isLoading: !0,
      stripe: null,
      oneStepLoginTest: Math.random() > 0.5
    }, this.onLoad = this.onLoad.bind(this);
  }
  constructUrl() {
    return new URL(
      `https://cm.vg.no/v2/${g[this.newsroom] || "vg"}/stripe`
    );
  }
  createLoginUrl() {
    var r;
    const t = new URL(window.location.href);
    t.searchParams.set(
      "utm_term",
      this.state.oneStepLoginTest ? "one-step" : "two-step"
    ), t.searchParams.set("utm_source", "stripe");
    const n = T(t.toString());
    return {
      loginUrl: (r = window.Identity) == null ? void 0 : r.loginUrl({
        oneStepLogin: this.state.oneStepLoginTest,
        state: n
      }),
      loginState: n
    };
  }
  async fetchStripeData() {
    const n = new AbortController().signal, r = await this.resolveAccount();
    if (this.isApp) {
      this.updateLoadingState(!1);
      return;
    }
    const s = v(window.location.toString(), !0), a = this.constructUrl();
    let c = "";
    try {
      const e = await r.getUser();
      c = e ? e.userId : "", c && a.searchParams.append("spid", String(c));
    } catch (e) {
      console.error("Failed to get user for paywall", e);
    }
    try {
      const e = await L(a.toString(), {
        credentials: "include",
        signal: n
      });
      if (e.status === 204) {
        this.updateLoadingState(!1);
        return;
      }
      if (e.status !== 200)
        throw new Error(`Failed to fetch stripe data: ${e.status}`);
      const h = S.createSalesposter(e, {
        contentProvider: g[this.newsroom] || "vg",
        contentType: this.contentType,
        deviceType: this.deviceType,
        paywallType: "stripe",
        contentId: this.contentId,
        document,
        window,
        utmTagOverrides: s ? {
          utm_campaign: s.campaign,
          utm_content: s.content,
          utm_medium: s.medium,
          utm_source: s.source,
          utm_term: s.terms,
          utm_url: s.url
        } : void 0
      });
      this.state.stripe = h;
    } catch (e) {
      console.error("Failed to generate stripe", e);
    } finally {
      this.updateLoadingState(!1);
    }
  }
  async onLoad(t) {
    try {
      const n = await this.resolveAccount(), { loginUrl: r, loginState: s } = this.createLoginUrl(), a = I({
        html: t.salesposter.html,
        campaign: t.salesposter.campaign,
        clientId: n.clientId,
        env: n.env,
        utmParams: {
          utm_content: t.pulseOrigin.content,
          utm_campaign: t.pulseOrigin.campaign,
          utm_source: t.pulseOrigin.source,
          utm_medium: t.pulseOrigin.channel,
          utm_term: t.pulseOrigin.terms,
          xDomainId: t.pulseOrigin.xDomainId
        },
        loginUrl: r,
        oneStepLoginTest: this.state.oneStepLoginTest,
        state: s,
        statisticsId: t.data.content_id
      });
      this.shadowRoot.innerHTML = "";
      const e = new DOMParser().parseFromString(a, "text/html");
      e.querySelectorAll("style").forEach((o) => {
        this.shadowRoot.appendChild(o.cloneNode(!0));
      }), Array.from(e.body.childNodes).forEach((o) => {
        this.shadowRoot.appendChild(o.cloneNode(!0));
      }), this.shadowRoot.querySelectorAll("script").forEach((o) => {
        const d = document.createElement("script");
        o.src ? d.src = o.src : d.textContent = o.textContent, o.parentNode.replaceChild(d, o);
      }), this.trackView(), sessionStorage.removeItem("hasSession-cache");
    } catch (n) {
      console.error("Failed to load stripe", n);
    }
  }
  resolveAccount() {
    return new Promise((t) => {
      window.Identity ? t(window.Identity) : window.addEventListener("identity-initialized", () => {
        t(window.Identity);
      });
    });
  }
  async trackView() {
    var s, a, c, e, h, m, o, d;
    if (!window.pulse) {
      console.error("Pulse not found on window object");
      return;
    }
    const t = await f.fromAutotracker(window.pulse), n = document.documentElement.dataset.pageType, r = [
      "salesposter",
      u(((s = this.state.stripe) == null ? void 0 : s.salesposter.posterType) || "stripe"),
      u(((a = this.state.stripe) == null ? void 0 : a.salesposter.contentName) || "unknown"),
      (c = this.state.stripe) != null && c.salesposter.campaign ? u(this.state.stripe.salesposter.campaign) : void 0,
      (e = this.state.stripe) != null && e.salesposter.segmentName ? u(this.state.stripe.salesposter.segmentName) : void 0,
      (h = this.state.stripe) != null && h.salesposter.abTestGroup ? u(this.state.stripe.salesposter.abTestGroup) : void 0
    ].filter(Boolean);
    t.trackViewUIElement({
      origin: (m = this.state.stripe) == null ? void 0 : m.pulseOrigin,
      object: {
        name: ((o = this.state.stripe) == null ? void 0 : o.salesposter.contentName) || "Salesposter Stripe",
        elementType: ((d = this.state.stripe) == null ? void 0 : d.salesposter.posterType) || "Salesposter - Stripe",
        id: r.join(":"),
        page: {
          id: this.contentId,
          url: window.location.href,
          type: n || "Page"
        },
        placement: {
          primaryPosition: 1
        }
      }
    });
  }
  updateLoadingState(t) {
    this.state.isLoading = t, this.render();
  }
  connectedCallback() {
    this.shadowRoot || this.attachShadow({ mode: "open" }), this.fetchStripeData();
  }
  async render() {
    !this.state.stripe || this.state.isLoading || this.isApp || await this.onLoad(this.state.stripe);
  }
}
const N = () => {
  customElements.get("vg-stripe") || customElements.define("vg-stripe", _);
};
export {
  N as default
};
